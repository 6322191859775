.home_promise_bg{
    max-width: 1300px;
    margin: 75px auto 84px;
    border-radius: 10px;
  }
  .home_promise_flex {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 30px;
}
  .home_pro_content {
    max-width: 600px;
    margin-left: 30px;
}
  .home_pro_img img {
    line-height: 0;
    margin-top: -24px;
    margin-bottom: -33px;
    max-width: 200px;
    display: inline-block;
}
  .home_promise span,.home_promise h2 ,.home_gurantee h2,.loox_allreview h2{
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 700;
    line-height: 20.8px;
    letter-spacing: 1px;
}
  .home_promise h2 ,.home_gurantee h2,.loox_allreview h2{
    font-size: 30px;
    line-height: 39px;
        margin: 0;
    letter-spacing: 0px;
  }
.home_gurantee h2,.loox_allreview h2{
  text-transform:capitalize;
}
.home_gurantee h2{font-size:36px;line-height:59px;}
  .home_promise p,.home_gurantee p {
    font-family: 'Libre Franklin';
    line-height: 30.6px;
    font-size: 17px;
    margin: 0;
    letter-spacing:0;
}
.home_gurantee p {
  line-height: 27px;
    font-size: 21px;
  padding-top:10px;
}
.home_gurantee_flex {
    /*display: flex;*/
  display:block;
    align-items: center;
    justify-content: center;
    padding: 30px 15px 50px;
  text-align:center;

}
.home_gurantee .home_pro_content {
    /*max-width: 590px;*/
  max-width: 830px;
  margin:20px auto 0;
}
.home_gurantee .home_pro_img img {
    margin: 0 auto;
}
.home_gurantee .home_pro_img {
    line-height: 0;
  margin-top:-100px;
}
.index-section.review_section {
    margin-bottom: 0px;
}
.loox_allreview h2 {
    margin: 0px auto;
    max-width: 1400px;
    padding: 70px 50px 0px;
  text-align:center;
}
.loox_allreview h2 span {
    color: #C3954C;
}
.loox_allreview div#looxReviews {
  padding: 0 50px;
}
.home_review .padding{
  padding:20px;
}
/* testimonial section css start */
.new-verified-customers {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 75px 0 0;
}
.new-verified-customers--ratingstars-container {
  display: flex;
  align-items: center;
}
.new-verified-customers--ratingstats {
  color: #1c1c1c;
  font-family: 'Poppins';
  font-size: 36px;
  line-height: 59px;
    font-weight: 600;
}
.new-verified-customers--desc p{
    margin: 30px auto 0;
    color: #242424;
    font-family: 'Poppins';
    text-align: center;
    max-width: 900px;
    font-size: 60px;
    font-weight: 300;
    line-height: 72px;
    letter-spacing: -1.8px;
}
.new-verified-customers--desc strong {
  color: #C3954C;
  font-style: italic;
  font-weight: 600;
}
.new-verified-customers--images {
  display: flex;
  margin-top: 50px;
  overflow-x: auto;
  flex-wrap: wrap;
  gap: 25px;
  justify-content: center;
}
.new-verified-customers--images::-webkit-scrollbar {
  display: none;
}
.new-verified-customers--images {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
.new-verified-customers--images video {
  width: 200px;
  height: 260px;
  object-fit: cover;
}
.video__wrapper {
  position: relative;
}
.video__wrapper video {
  border-radius: 20px;
  overflow: hidden;
}
.poster__text {
  display: grid;
  gap: 10px;
  grid-template-columns: 3fr 1fr;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 31px 0 15px 8px;
  overflow: hidden;
  border-radius: 0 0 20px 20px;
  z-index: 2;
}
.poster__text::before {
  position: absolute;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000e0 60%, #000000 80%, #000000 100%);
  z-index: 1;
}
.video__start svg {
  color: white;
  width: 50px;
  height: 50px;
}
.poster__text h3 {
  font-size: 16px;
    color: #fff;
    margin-bottom: 3px;
    font-weight: 700;
    line-height: 17.6px;
    text-transform: uppercase;
}
.poster__text p {
  font-size: 12px;
  line-height: 15.6px;
  color: #fff;
font-family: 'Libre Franklin';
  margin:0px;
  letter-spacing:0px;
}
.poster__content, .video__start {
  position: relative;
  z-index: 2;
}
.icon-pause {
  display: none;
}
.new-verified-customers--ratingstars li{margin-bottom:0px;}
.video__popup {
    display: none;
    background-color: #00000080;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.video__close {
    color: #fff;
    font-size: 30px;
    line-height: 1;
    text-align: right;
    max-width: 400px;
    width: 90vw;
    font-weight: 700;
    margin-bottom: 5px;
    cursor: pointer;
}
.video__popup video {
    max-width: 400px;
    width: 90vw;
}
/* PDP Gurante Section  */
.gurante-wrapper-flex {
    display: flex;
    align-items: center;
}
.gurante-wrapper-img {
    max-width: max-content;
    width: 100%;
    line-height: 0;
}
.gurante-wrapper-content p {
   font-family: 'Libre Franklin';
    font-size: 16px;
    font-weight: 600;
    line-height: 25.6px;
    letter-spacing: 0.3px;
  text-align:left;
}
.gurante-wrapper-content {
    margin-left: 20px;
}
.gurante-wrapper {
    margin-bottom: 30px;
}
@media(min-width:768px){
.announcement-slider__slide:first-child {text-align: left;}
.announcement-slider__slide:last-child {text-align: right;}
  .announcement-link{display:inline-block;}
}
@media(max-width:1365px){
  .home_promise_bg{margin: 45px 40px 60px;}
}
@media(max-width:1199px){
  .home_promise_flex{padding-left:0px;}
  .new-verified-customers--ratingstats{font-size:32px;line-height: 48px;}
  .new-verified-customers--desc p {font-size: 50px;line-height: 64px;}
}
@media(max-width:991px){
  .home_pro_content{margin-left:10px;}
  .home_promise p,.home_gurantee p{line-height: 22.6px;font-size: 14px;}
  .home_promise h2 ,.home_gurantee h2,.loox_allreview h2{font-size: 26px;line-height: 32px;}
  .home_promise_bg {margin: 35px 30px 20px;}
  .loox_allreview h2 {padding: 40px 30px 0;}
  .loox_allreview div#looxReviews{padding:0 30px;}
  .home_review .padding{padding:0px;}
  .new-verified-customers{padding-top:40px;}
  .new-verified-customers--desc p {font-size: 46px;line-height: 52px;margin-top: 20px;}
  .new-verified-customers--images{margin-top:30px;}
}
@media(max-width:768px){
.home_gurantee {padding-top: 60px;}
}
@media(max-width:767px){
  .home_promise_bg {margin: 35px 17px 20px;}
  .home_promise_flex,.home_gurantee_flex {display: block;text-align: center;padding: 0 15px 20px;}
  .home_pro_content,.home_gurantee .home_pro_content {margin-left: 0;max-width: 100%;}
  .home_pro_img {max-width: 35%;margin: auto;}
  .home_pro_img img{margin-bottom:5px;}
  .home_gurantee .home_pro_img{margin: auto;}  
  .loox_allreview h2 {padding: 35px 17px 0!important;}
  .loox_allreview div#looxReviews{padding:0 17px;}
  .new-verified-customers--ratingstats {font-size: 12px;line-height: 18px;}
  .new-verified-customers--ratingstars li {width: 18px;}
  .new-verified-customers--images {flex-wrap: nowrap;overflow-x: scroll;max-width: 100%;padding: 0 20px;justify-content: unset;}
  .new-verified-customers--images img {width: 215px;}
  .new-verified-customers--ratingstars li svg {display: block;width: 100%;}
  .new-verified-customers--desc p {font-size: 38px;line-height: 44px;}
  .home_gurantee .home_pro_img img{margin-top:-60px;}

}
@media(max-width:480px){
  .home_promise h2 ,.home_gurantee h2,.loox_allreview h2{font-size: 22px;line-height: 28px;padding: 5px 0;}
  .home_pro_img img{margin-top: -4vw;}
  .home_gurantee h2{padding-bottom:0px;}
  .new-verified-customers--desc p {font-size: 34px;line-height: 38px;margin: 0px 16px;}
  .gurante-wrapper-flex{display:block;}
  .gurante-wrapper-img {max-width: 100%;padding-bottom: 10px;}
  .gurante-wrapper-content p {text-align:center;}
  .gurante-wrapper-content {margin-left: 0;}
  .home_gurantee .home_pro_img img{margin-top:-40px;}
  .home_gurantee {padding-top: 40px;}
  .home_gurantee .home_pro_content{margin-top:0px;}
  .announcement-text{letter-spacing:1px;}
}


body button.needsclick.kl-teaser-VZCWXF.undefined.kl-private-reset-css-Xuajs1 span{    font-size: 14px !important;text-transform: lowercase !important;}
body button.needsclick.kl-teaser-VZCWXF.undefined.kl-private-reset-css-Xuajs1 {width: 130px !important;}

